import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle
} from '@fortawesome/pro-regular-svg-icons';
import {
  SnackbarProvider as NotistackSnackbarProvider,
  withSnackbar
} from 'notistack';
import Action from '../Snackbar/Action';

const styles = theme => ({
  root: {
    maxWidth: theme.breakpoints.values.sm,
    marginTop: 152
  },
  info: {
    background: theme.palette.primary.dark
  },
  success: {
    background: theme.palette.success.main
  },  
  error: {
    background: theme.palette.error.main
  },
  errorLongMessage: {
    background: theme.palette.error.main,
    flexWrap: 'nowrap',
  },
  iconInfo: {
    color: theme.palette.primary.contrastTextSecondary,
    marginRight: 8
  },
  iconSuccess: {
    color: theme.palette.success.contrastTextSecondary,
    marginRight: 8
  },
  iconError: {
    color: theme.palette.error.contrastTextSecondary,
    marginRight: 8
  },
  contentRoot: {
    flexWrap: 'nowrap'
  }
});

class SnackbarProvider extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired
  };

  render() {
    const { classes, children } = this.props;

    return (
      <NotistackSnackbarProvider
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        preventDuplicate
        classes={{
          root: classes.root,
          variantInfo: classes.info,
          variantSuccess: classes.success,
          variantWarning: classes.error,
          variantError: classes.error,
          variantErrorLongMessage: classes.errorLongMessage
        }}
        autoHideDuration={5000}
        action={key => <Action variant="close" snackKey={key} />}
        iconVariant={{
          info: (
            <FontAwesomeIcon
              icon={faInfoCircle}
              className={classes.iconInfo}
              size="lg"
            />
          ),
          success: (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={classes.iconSuccess}
              size="lg"
            />
          ),
          warning: (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className={classes.iconError}
              size="lg"
            />
          ),
          error: (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className={classes.iconError}
              size="lg"
            />
          ),
          errorLongMessage: (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className={classes.iconError}
              size="lg"
            />
          )
        }}
      >
        {children}
      </NotistackSnackbarProvider>
    );
  }
}

export default withStyles(styles)(SnackbarProvider);
export { withSnackbar };
