import React, { Component, Fragment } from 'react';

import RenderCmsComponent from './renderComponents';
import Grid from '@material-ui/core/Grid';

class Containers extends Component {
 
  render() {
    const { data, id, mode, placeholders, componentDetails, componentsDefinition} = this.props;
	
	return (	
			<Grid container  id={id}>{data.rows.map((row, j) =><Fragment key={j}><RenderCmsComponent renderComponent={row.type} mode={mode} placeholders={placeholders} id={row.id} data={row} componentDetails = {componentDetails} componentsDefinition={componentsDefinition}/></Fragment>)}</Grid>		
	);	
  }
}

export default Containers;
