import React, { Component, Fragment } from 'react';

import RenderCmsComponent from './renderComponents';
import Grid from '@material-ui/core/Grid';


/**
This component wraps the components in columns
*/
class Columns extends Component {
 
  render() {
    const { data, id, mode, placeholders, componentDetails, componentsDefinition} = this.props;
	var styles = "";	
	var styleClass = "";
	data.styles.map((item)=> styleClass+=item+" ");
	if(mode === 'edit' || mode === 'template'){
		styles = "iw_columns iw_firstchild iw_lastchild "+styleClass;
	}
	//Get the column size for the grid
	var columnNumber = parseInt(styleClass.substring(styleClass.lastIndexOf("-")+1),10);
		
	return (
				<Grid  item  xs={12} sm={12} lg={columnNumber} id={id} className={styles}>
					{data.contents.map((item,k) => 
						<Fragment key={k}> <RenderCmsComponent renderComponent={item.type} mode={mode} placeholders={placeholders} id={item.id} data={item} componentDetails = {componentDetails} componentsDefinition={componentsDefinition}/></Fragment>
					)}
				</Grid>
			);
	
  }
}

export default Columns;
