import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
  static propTypes = {
    children: PropTypes.any,
    exclude: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  };

  static defaultProps = {
    exclude: []
  };

  componentDidUpdate(prevProps) {
    const { location, exclude } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      const isExcluded =
        typeof exclude === 'string'
          ? exclude === location.pathname
          : exclude.includes(location.pathname);

      if (!isExcluded) {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children || null;
  }
}

export default withRouter(ScrollToTop);
