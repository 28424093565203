import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import { USER_QUERY } from '../../Broker/graphQL/queries';
import CmsPage from '../../Shared/components/TeamSiteCore/Page';
import { PREVIEW_MODE } from '../../Shared/utils/teamSiteConstants';
import Context from '../../Context';
import Error from '../Error';

// Mapping of all the components created in teamsite.
// key is the name of the component from page json response and right side is the react component
import { COMPONENTS_DEFINITION as componentsDefinition } from './ComponentsDefinition';

const errorToErrorMessage = (error) => {
  // error is not null/undefined
  if (!error) return null;

  // Only returned a graphQLErrors
  if (error.networkError === null && error.graphQLErrors !== null) {
    return {
      statusCode: '400',
      title: 'Bad Request',
      message: error.graphQLErrors.map((e) => e.message).join('; '),
      exceptions: '',
    };
  }

  // returned neither a network error or a graphQLError
  if (error.networkError === null && error.graphQLErrors === null) return null;

  if (error.networkError.result !== null) {
    const msg = {
      statusCode: error.networkError.statusCode,
    };
    // custom network error
    if (error.networkError.result.message) {
      msg.title = error.networkError.result.title;
      msg.message = error.networkError.result.message;
      msg.exceptions = error.networkError.result.exceptions
        ?.map(
          (ex) => `message: "${ex.message}"; stacktrace: "${ex.stacktrace}";`
        )
        .join('');
    }
    // default network errors
    else {
      msg.title = error.networkError.name;
      msg.message = error.networkError.message;
    }
    return msg;
  }

  // default reponse
  return {
    statusCode: error.networkError.statusCode,
    title: error.networkError.name,
    message: error.networkError.bodyText,
    exceptions: '',
  };
};

const Page = ({ pageModel: propsModel, mode: propsMode, match }) => {
  const { setCurrentUser, currentUser, log } = useContext(Context);
  const [pageModel, setPageModel] = useState(propsModel);
  const [mode, setMode] = useState(propsMode);
  const [pageError, setPageError] = useState(false);
  const { pathname } = window.location;
  const [url, setUrl] = useState(pathname);

  // update url if pathname changes
  useEffect(() => {
    if (url !== pathname) {
      setUrl(pathname);
      setPageError(false);
    }
  }, [url, pathname]);

  /**
   * Retrieve current user is one is not set in the global context.
   */
  const { data, error } = useQuery(USER_QUERY, { skip: !!currentUser });

  useEffect(() => {
    if (error) {
      setPageError(true);
    }
    if (data?.user) {
      setCurrentUser(data.user);
    }
  }, [data, error]);

  /**
   * Fetch Page model using the LSDS URL. Set the proxy for the LSDS server to make this call.
   * @param {*} url
   * @param {*} mode
   */
  const fetchPageModel = async (fetchUrl, pageMode, attempts = 1) => {
    attempts--;
    fetch(fetchUrl)
      .then(res => res.json())
      .then(
        (result) => {
          setPageModel(result);
          setMode(pageMode);
        },
        (error) => {
          if (attempts > 0) {
            log.error(JSON.stringify({
              message:`retrying LSDS Page Fetch, attempt number ${attempts + 1}`,
              error: error
            }));  
            return fetchPageModel(fetchUrl, pageMode, attempts);
          }
          log.error(
            JSON.stringify({
              message: 'LSDS Page fetch retry exceeded maximum number of attempts',
              error: error,
            })
          );
          setPageError(true);
          return error;
        }
      );
  };

  /**
   * Fetch page model by constructing the path for accessing the .page in the LSDS. By default,
   * in this app the mode for access will always be runtime.
   */
  useEffect(() => {
    if (currentUser) {
      let fetchUrl = url;
      if (match?.path?.includes('/:')) {
        fetchUrl = match.path.substring(0, match.path.indexOf('/:'));
      }
      fetchPageModel(
        `${window._env_.REACT_APP_LSDS_SERVER_URL.trim()}${fetchUrl}.page`,
        PREVIEW_MODE.RUNTIME,
        3
      );
    } else {
      log.error(
        JSON.stringify({
          message: " Current User is null"
        })
      );

    }
  }, [currentUser, url]);

  const errorMsg = errorToErrorMessage(error);

  useEffect(() => {
    if (log?.error && error && pageError) {
      log.error(
        JSON.stringify({
          message: 'page.js received a fatal error',
          error,
        })
      );
    }
  }, [log, error, pageError]);

  // Show error in case the page is not found in lsds
  return pageError ? (
    <Error
      location={{
        state: {
          errorCode: errorMsg?.statusCode ?? '500',
          title: errorMsg?.title ?? 'Unknown',
          message: errorMsg?.message ?? 'An unknown error has occurred.',
          exceptions: errorMsg?.exceptions ?? '',
          returnTo: '/',
        },
      }}
    />
  ) : (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <title>{pageModel && pageModel.page ? pageModel.page.title : ''}</title>
        <meta
          name="description"
          content={
            pageModel && pageModel.page ? pageModel.page.description : ''
          }
        />
        <meta
          name="keywords"
          content={pageModel && pageModel.page ? pageModel.page.keywords : ''}
        />
      </Helmet>
      <CmsPage
        model={pageModel}
        mode={mode}
        componentsDefinition={componentsDefinition}
      />
    </Suspense>
  );
};

Page.propTypes = {
  pageModel: PropTypes.object,
  mode: PropTypes.object,
  match: PropTypes.object,
};
Page.defaultProps = {
  pageModel: {},
  mode: {},
  match: {},
};

const enhance = compose(withApollo);
export default enhance(Page);
