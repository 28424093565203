import React from 'react';
import container from './Containers';
import row from './Rows';
import column from './Columns';
import placeholder from './PlaceHolder';
import component from './TeamSiteComponents';
import UndefinedComponent from './undefined';

// Mapping of common components for layout rendering. key is the name of the component coming from page json response and right side is the react component
const teamSiteComponentsDefinition = {
	"container": container,
	"row": row,
	"column": column,
	"placeholder": placeholder,
	"component": component,		
	"UnsupportedComponent": UndefinedComponent,
};

/**
This component renders all the components using componentsDefinition
*/	
class RenderCmsComponent extends React.Component { 
  render() {
    const { data, id, mode, placeholders, renderComponent, componentDetails, componentsDefinition } = this.props;
		let TypeComponent = null;
		if (renderComponent in teamSiteComponentsDefinition) {
			TypeComponent = teamSiteComponentsDefinition[renderComponent];
    } else if (renderComponent in componentsDefinition) {
			TypeComponent = componentsDefinition[renderComponent];	
		}
		if (TypeComponent === null) {
			return <UndefinedComponent data={data} id={id} mode={mode}/>;
		}
		return (
			React.createElement(TypeComponent, { data, placeholders, id, mode, componentDetails, componentsDefinition })
		);
	}
}

export default RenderCmsComponent;
