(function (global, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['module', 'exports'], factory);
  } else if (typeof exports !== 'undefined') {
    factory(module, exports);
  }
})(this, function (module, exports) {
  'use strict';

  Object.defineProperty(exports, '__esModule', {
    value: true,
  });

  const customJSON = (log) => ({
    msg: log.message,
    level: log.level.label,
    stacktrace: log.stacktrace,
  });

  var logging = {
    getLogger: function getLogger(log, remote, url, token) {
      log.enableAll();
      remote.apply(log, {
        format: customJSON,
        url: url + '/logging',
        headers: { Authorization: `Bearer ${token}` },
      });
      return {
        log: log,
        remote: remote,
      };
    },
    getCustomJSON: function getCustomJSON(log) {
      if (!!log) {
        return customJSON(log);
      } else {
        return null;
      }
    },
  };

  exports.default = logging;
  module.exports = exports['default'];
});
