import React from 'react';
import { Redirect } from 'react-router-dom';

const NotFound = () => (
  <Redirect
    to={{
      pathname: '/error',
      state: {
        errorCode: '404',
        title: 'Page not found',
        message: "Sorry, we can't find the page you were looking for.",
      },
    }}
  />
);

export default NotFound;
