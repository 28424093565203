export const PREVIEW_MODE = {
  EDIT: 'edit',
  PREVIEW: 'preview',
  RUNTIME: 'runtime',
  TEMPLATE: 'template',
};

export const DATUM_TYPES = {
  BOOLEAN: 'Boolean',
  CHECKBOX_GROUP: 'CheckboxGroup',
  DATE: 'Date',
  DCR: 'DCR',
  FONT: 'Font',
  IMAGE: 'Image',
  NUMBER: 'Number',
  RADIO_GROUP: 'RadioGroup',
  SELECT_MULTIPLE: 'SelectMultiple',
  SINGLE_SELECT: 'SelectSingle',
  STRING: 'String',
  TEXTAREA: 'Textarea',
};

export const CONTAINER_TYPES = {
  COMPONENT: 'component',
  CONTAINER: 'container',
  COLUMN: 'column',
  PLACEHOLDER: 'placeholder',
  ROW: 'row',
};

export const PAGE_EXTENSIONS = {
  PAGE: '.page',
  SEED: '.seed',
};

export const TEAMSITE_CONSTANTS = [
  PREVIEW_MODE,
  DATUM_TYPES,
  CONTAINER_TYPES,
  PAGE_EXTENSIONS,
];
