import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import Container from 'Shared/components/Container';
import Link from 'Shared/components/Link';
import Paper from 'Shared/components/Paper';
import Typography from 'Shared/components/Typography';

const styles = (theme) => ({
  root: {
    marginTop: '64px',
    marginBottom: '64px',
  },
  paper: {
    padding: '50px',
  },
  errorCode: {
    fontSize: theme.typography.pxToRem(50),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1,
    marginBottom: '16px',
  },
  title: {
    marginBottom: '40px',
  },
  message: {
    marginBottom: '24px',
  },
  arrowIcon: {
    marginRight: '8px',
  },
});

const Error = ({ classes, location }) => {
  const state = {
    errorCode: '500',
    back: true,
    title: 'Error',
    message: 'There was an unexpected error. Please try again.',
    returnTo: '/',
    ...(location ? location.state : {}),
  };

  return (
    <Container
      data-test-id="error-page"
      className={classes.root}
      size="small"
      centered
    >
      <Paper className={classes.paper}>
        <Typography
          variant="inherit"
          color="textPrimary"
          component="h2"
          align="center"
          className={classes.errorCode}
          translate={false}
        >
          {state.errorCode}
        </Typography>
        <Typography
          variant="h3"
          color="textPrimary"
          align="center"
          className={classes.title}
        >
          {state.title}
        </Typography>
        <Typography
          color="textPrimary"
          align="center"
          className={classes.message}
        >
          {state.message}
        </Typography>
        {state.back && (
          <Link to={state.returnTo} display="block" align="center">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={classes.arrowIcon}
              size="sm"
            />
            Go Back
          </Link>
        )}
      </Paper>
    </Container>
  );
};

Error.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
};
Error.defaultProps = {
  classes: {},
  location: {},
};

export default withStyles(styles)(Error);
