import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { withSnackbar } from 'notistack';
import IconButton from '../IconButton';
import Link from '../Link';
import Button from '../Button';

class Action extends Component {
  static displayName = 'Snackbar.Action';

  static propTypes = {
    variant: PropTypes.oneOf(['default', 'close', 'button', 'other']),
    snackKey: PropTypes.number.isRequired
  };

  handleClick = event => {
    const { onClick, closeSnackbar, snackKey } = this.props;

    closeSnackbar(snackKey);
    if (onClick) {
      onClick(event);
    }
  };

  render() {
    const {
      onClick,
      closeSnackbar,
      enqueueSnackbar,
      snackKey,
      variant,
      children,
      ...other
    } = this.props;

    switch(variant) {
      case 'close':
        return (
          <IconButton
            data-test-id='snackbar-action-icon-button'
            color="inherit"
            aria-label="dismiss"
            onClick={this.handleClick}
            {...other}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{ width: '1rem', height: '1rem' }}
            />
          </IconButton>
        );
      case 'button':
        return (
          <Button
            data-test-id='snackbar-action-button'
            onClick={this.handleClick}
            {...other}>
            {children}
          </Button>
        );
      default:
        return (
          <Link
            data-test-id='snackbar-action-link'
            component="button"
            color="inherit"
            underline="always"
            onClick={this.handleClick}
            {...other}
          >
            {children}
          </Link>
        );
    }
  }
}

export default withSnackbar(Action);
