import React, { Component } from 'react';
import { compose } from 'lodash/fp';
import { withStyles } from '@material-ui/core/styles';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';
import Context from 'Context';
import Snackbar from 'Shared/components/Snackbar';

const styles = () => ({
  actionButton: {
    marginRight: '20px',
    paddingLeft: '9px',
    paddingRight: '9px',
  },
});

class InactivityTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeoutMultiplier: 1000,
      logoutTimer: null,
      showSnackBar: false,
    };
  }

  onAction = () => {
    const { logoutTimer } = this.state;
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    this.setState({ logoutTimer: null, showSnackBar: false });
  };

  onIdle = () => {
    const { auth } = this.context;
    const { timeoutMultiplier } = this.state;
    const { logoutTimeoutSeconds } = this.props;
    const logoutTimer = setTimeout(
      auth.logout,
      logoutTimeoutSeconds * timeoutMultiplier
    );
    this.setState({ logoutTimer, showSnackBar: true });
  };

  render() {
    const { timeoutMultiplier, showSnackBar } = this.state;
    const { auth } = this.context;
    const { activityTimeoutSeconds, classes } = this.props;

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={activityTimeoutSeconds * timeoutMultiplier}
        />
        {showSnackBar && !!auth && (
          <Snackbar
            variant="info"
            message="Your session is about to time out, would you like to continue your session?"
            persist
            action={(key) => (
              <>
                <Snackbar.Action
                  data-test-id="inactivity-timer-continue"
                  snackKey={key}
                  onClick={this.onAction}
                  className={classes.actionButton}
                  variant="button"
                >
                  Continue Session
                </Snackbar.Action>
                <Snackbar.Action
                  snackKey={key}
                  onClick={auth.logout}
                  data-test-id="inactivity-timer-logout"
                  variant="button"
                  color="primary"
                >
                  Logout
                </Snackbar.Action>
              </>
            )}
          />
        )}
      </>
    );
  }
}

InactivityTimer.contextType = Context;
InactivityTimer.propTypes = {
  classes: PropTypes.object.isRequired,
  activityTimeoutSeconds: PropTypes.number.isRequired,
  logoutTimeoutSeconds: PropTypes.number.isRequired,
};

const enhance = compose(withStyles(styles));
export default enhance(InactivityTimer);
