import React from 'react';
import ReactDOM from 'react-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import TagManager from 'react-gtm-module';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

import ThemeProvider from './Shared/components/ThemeProvider';
import SnackbarProvider from './Shared/components/SnackbarProvider';
import CssBaseline from './Shared/components/CssBaseline';
import ScrollToTop from './Shared/components/ScrollToTop';
import './Broker/i18n';
import Auth from './Broker/components/Auth';
import { Provider } from './Context';
import App from './App';
import * as serviceWorker from './serviceWorker';
import logging from './components/Logging';

import './assets/css/custom.css';
import './assets/css/cms-specific-styles.css';

const client = new ApolloClient({
  uri: window._env_.REACT_APP_BROKER_API_URL,
  request(operation) {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
    };
    operation.setContext({ headers });
  },
});

const logger = logging.getLogger(
  log,
  remote,
  window._env_.REACT_APP_BROKER_API_URL,
  sessionStorage.getItem('accessToken')
);
// Instructions on using UI logging are in the README.
// Logging types
// log.debug(JSON.stringify(testLogObject));
// log.error(JSON.stringify(testLogObject));
// log.info(JSON.stringify(testLogObject));
// log.trace(JSON.stringify(testLogObject));
// log.warn(JSON.stringify(testLogObject));

const auth = new Auth({
  domain:
    window._env_.AUTH0_SWITCH === 'false'
      ? window._env_.REACT_APP_AUTH_DOMAIN
      : window._env_.REACT_APP_AUTH_DOMAIN_BUSINESS,
  clientID:
    window._env_.AUTH0_SWITCH === 'false'
      ? window._env_.REACT_APP_AUTH_CLIENT_ID
      : window._env_.REACT_APP_AUTH_CLIENT_ID_BUSINESS,
  redirectUri: `${window.location.origin}/login`,
  audience:
    window._env_.AUTH0_SWITCH === 'false'
      ? window._env_.REACT_APP_AUTH_AUDIENCE
      : window._env_.REACT_APP_AUTH_AUDIENCE_BUSINESS,
  responseType: 'token id_token',
  scope: 'openid profile email',
  realm: 'B2B',
});

const tagManagerArgs = {
  gtmId: window._env_.REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider auth={auth} log={logger.log} remote={logger.remote}>
    <ApolloProvider client={client}>
      <Router>
        <LastLocationProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <CssBaseline />
              <ScrollToTop exclude={['/profile', '/my-team']} />
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </LastLocationProvider>
      </Router>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
