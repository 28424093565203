import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    boxSizing: 'content-box',
    padding: '0 16px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 24px'
    }
  },
  centered: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  small: {
    maxWidth: theme.breakpoints.values.sm
  },
  medium: {
    maxWidth: theme.breakpoints.values.md
  },
  large: {
    maxWidth: theme.breakpoints.values.lg
  },
  full: {
    maxWidth: 'none'
  }
});

class Container extends Component {
  static propTypes = {
    centered: PropTypes.bool,
    children: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'full'])
  };

  static defaultProps = {
    size: 'full'
  };

  render() {
    const {
      centered,
      children,
      classes,
      className,
      size,
      ...other
    } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          { [classes.centered]: centered },
          classes[size],
          className
        )}
        {...other}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Container);
