import { gql } from 'apollo-boost';

export const USER_FRAGMENT = gql`
  fragment User on UserType {
    id
    auth0Id
    fullName
    givenName
    familyName
    preferredName
    displayName
    email
    agencyName
    agencyId
    commissionsAccess
    customURL
    role
    status
    isBroker
    hasIBMAccess
    challengeQuestionsStatus
    challengeQuestions{
      questionText
      questionId
    }
    phone {
      extension
      number
    }
    __typename
  }
`;

export const AUTH0USER_FRAGMENT = gql`
  fragment Auth0User on Auth0UserType {
    id
    email
    fullName
    displayName
    phone {
      extension
      number
    }
    givenName
    familyName
    agencyName
    preferredName
    isBroker
    status
    role
    __typename
  }
`;
