import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = (theme) => ({
  bold: {
    fontWeight: `bold`,
  },
});

class Typography extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
    bold: PropTypes.bool,
    children: PropTypes.any,
    color: PropTypes.oneOf([
      'initial',
      'error',
      'inherit',
      'primary',
      'textPrimary',
      'textSecondary',
    ]),
    component: PropTypes.elementType,
    count: PropTypes.number,
    display: PropTypes.oneOf(['initial', 'block', 'inline']),
    gutterBottom: PropTypes.bool,
    noWrap: PropTypes.bool,
    paragraph: PropTypes.bool,
    translate: PropTypes.bool,
    variant: PropTypes.oneOf([
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'body1',
      'body2',
      'caption',
      'button',
      'overline',
      'srOnly',
      'inherit',
    ]),
  };

  static defaultProps = {
    align: 'inherit',
    color: 'inherit',
    display: 'initial',
    translate: true,
    variant: 'body1',
  };

  render() {
    const {
      children,
      bold,
      forwardedRef,
      translate,
      count,
      loading,
      // Filter out unused material-ui props
      classes,
      headlineMapping,
      internalDeprecatedVariant,
      className,
      ...other
    } = this.props;

    if (loading) {
      return <Skeleton variant="text" />;
    }

    return (
      <MuiTypography
        {...other}
        ref={forwardedRef}
        className={classNames(
          {
            [classes.bold]: bold,
          },
          className
        )}
      >
        {children}
      </MuiTypography>
    );
  }
}

const StyledTypography = withStyles(styles)(Typography);
export default React.forwardRef((props, ref) => (
  <StyledTypography {...props} forwardedRef={ref} />
));
