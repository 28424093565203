import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiCircularProgress from '@material-ui/core/CircularProgress';

const sizeMap = {
  small: 20,
  medium: 40,
  large: 60
};

const StyledCircularProgress = withStyles(theme => ({
  circleStatic: {
    transition: theme.transitions.create('stroke-dashoffset', { duration: 0 })
  }
}))(MuiCircularProgress);

const styles = theme => ({
  root: {
    display: 'inline-block'
  },
  small: {
    height: sizeMap.small
  },
  medium: {
    height: sizeMap.medium
  },
  large: {
    height: sizeMap.large
  },
  left: {
    justifyContent: 'flex-start'
  },
  center: {
    justifyContent: 'center'
  },
  right: {
    justifyContent: 'flex-end'
  },
  block: {
    display: 'flex'
  }
});

class CircularProgress extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['left', 'center', 'right']),
    block: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'inherit']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    value: PropTypes.number,
    variant: PropTypes.oneOf(['indeterminate', 'static'])
  };

  static defaultProps = {
    align: 'center',
    color: 'primary',
    size: 'medium',
    value: 0,
    variant: 'indeterminate'
  };

  render() {
    const {
      align,
      block,
      classes,
      className,
      color,
      size,
      value,
      variant,
      // Filter out unsupported material-ui props
      disableShrink,
      thickness,
      ...other
    } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          classes[size],
          classes[align],
          { [classes.block]: block },
          className
        )}
        {...other}
      >
        <StyledCircularProgress
          color={color}
          size={sizeMap[size]}
          value={value}
          variant={variant}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CircularProgress);
