import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Typography from '../Typography';
import Action from './Action';

class Snackbar extends Component {
  static Action = Action;

  static propTypes = {
    action: PropTypes.element,
    ContentProps: PropTypes.object,
    message: PropTypes.any,
    onClose: PropTypes.func,
    onEnter: PropTypes.func,
    onEntered: PropTypes.func,
    onEntering: PropTypes.func,
    onExit: PropTypes.func,
    onExited: PropTypes.func,
    onExiting: PropTypes.func,
    persist: PropTypes.bool,
    translate: PropTypes.bool,
    variant: PropTypes.oneOf(['info', 'success', 'error'])
  };

  static defaultProps = {
    translate: true,
    variant: 'info'
  };

  componentDidMount() {
    const {
      action: actionProp,
      enqueueSnackbar,
      message,
      persist: persistProp,
      translate,
      variant,
      closeSnackbar, // Filter out notistack prop
      ...other
    } = this.props;

    const persist =
      persistProp !== undefined
        ? persistProp
        : variant === 'error'
        ? true
        : false;

    const action = key => (
      <>
        {actionProp && actionProp(key)}
        <Action variant="close" snackKey={key} />
      </>
    );

    enqueueSnackbar(
      <Typography
        data-test-id={this.props['data-test-id']}
        variant="inherit"
        color="inherit"
        translate={translate}
      >
        {message}
      </Typography>,
      { action, persist, variant, ...other }
    );
  }

  render() {
    return null;
  }
}

export default withSnackbar(Snackbar);
