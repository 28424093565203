import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './locales/en/translation.json';

// This configuration bundles translation strings in the JS bundle at build time.
// This is probably fine for now while there is only one language, but will lead
// to larger bundles down the road. Once we add more languages, we should switch
// to one of the i18next backends that can fetch the translations asynchronously,
// such as i18next-xhr-backend.
// See https://www.i18next.com/overview/plugins-and-utils#backends
i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: { translation: translationEn }
  },
  debug: process.env.NODE_ENV === 'development',
  keySeparator: false,
  interpolation: {
    escapeValue: false // React escapes by default
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: []
  }
});
