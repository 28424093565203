import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Context from '../../../Context';

// Supports same props as react-router-dom Route component
const SecureRoute = ({ component: Component, render, ...other }) => {
  const { auth } = useContext(Context);

  return (
    <Route
      {...other}
      render={(props) => {
        if (!auth.isAuthenticated()) {
          auth.login(props.location);
          return null;
        }
        return render ? render(props) : <Component {...props} />;
      }}
    />
  );
};

SecureRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
  render: PropTypes.func,
};

SecureRoute.defaultProps = {
  component: null,
  render: null,
  location: {},
};

export default SecureRoute;
