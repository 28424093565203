import React,{ Fragment } from 'react';

export default class UndefinedComponent extends React.Component {
  // fallback component when unknown/undefined component type is used
  render() {
	  const { data, mode} = this.props;
	  let message = "this";
	  let deleteLabel = "Please delete this component.";
	 
	  if(mode === 'edit' || mode === 'template'){
		  
		   return (
			  <p>
			    	Component "{ data.path }" is <strong>not supported</strong> by {message} site. {deleteLabel}
			  </p>
			);
	  }
    return (
      <Fragment>
      </Fragment>
    );
  }
}