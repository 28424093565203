import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as ReactRouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../Typography';

const styles = (theme) => ({
  underlineNone: {
    textDecoration: 'none',
  },
  underlineHover: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  underlineAlways: {
    textDecoration: 'underline',
  },
  // Button style reset from material-ui ButtonBase component
  button: {
    position: 'relative',
    // Remove grey highlight
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent', // Reset default value
    border: 0,
    margin: 0, // Remove the margin in Safari
    borderRadius: 0,
    padding: 0, // Remove the padding in Firefox
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    '-moz-appearance': 'none', // Reset
    '-webkit-appearance': 'none', // Reset
    '&::-moz-focus-inner': {
      borderStyle: 'none', // Remove Firefox dotted outline.
    },
  },
  disabled: {
    color: theme.palette.text.primary,
    cursor: 'default',
  },
});

class Link extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
    children: PropTypes.any.isRequired,
    color: PropTypes.oneOf([
      'initial',
      'error',
      'inherit',
      'primary',
      'textPrimary',
      'textSecondary',
    ]),
    display: PropTypes.oneOf(['initial', 'block', 'inline']),
    component: PropTypes.elementType,
    href: PropTypes.string,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func,
    ]),
    translate: PropTypes.bool,
    underline: PropTypes.oneOf(['none', 'hover', 'always']),
    variant: PropTypes.oneOf([
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'body1',
      'body2',
      'caption',
      'button',
      'overline',
      'srOnly',
      'inherit',
    ]),
  };

  static defaultProps = {
    align: 'left',
    color: 'primary',
    display: 'inline',
    translate: true,
    underline: 'hover',
    variant: 'inherit',
  };

  render() {
    const {
      align,
      block,
      children,
      classes,
      className,
      component,
      display,
      forwardedRef,
      href,
      to,
      underline,
      disabled,
      // Filter out unsupported material-ui props
      TypographyClasses,
      ...other
    } = this.props;

    // Render Link as component prop, react-router Link or <a> tag based on props
    const LinkComponent = component ? component : to ? ReactRouterLink : 'a';

    return (
      <Typography
        component={LinkComponent}
        ref={forwardedRef}
        className={classNames(
          {
            [classes.underlineNone]: underline === 'none',
            [classes.underlineHover]: underline === 'hover' && !disabled,
            [classes.underlineAlways]: underline === 'always' && !disabled,
            [classes.button]: component === 'button',
            [classes.disabled]: disabled,
          },
          className
        )}
        display={display}
        to={to}
        href={href}
        align={align}
        disabled={disabled}
        {...other}
      >
        {children}
      </Typography>
    );
  }
}

const StyledLink = withStyles(styles)(Link);

export default React.forwardRef((props, ref) => (
  <StyledLink {...props} forwardedRef={ref} />
));
