import React, { Component, Fragment } from 'react';

import TeamSiteComponents from './TeamSiteComponents';
class PlaceHolder extends Component {
 
  render() {
    const { data, id, mode, placeholders, componentDetails, componentsDefinition} = this.props;
	var max=0;
	var placeholderTitle ="";
	//Get the maximum number of components that can be added to placeholders
	max = placeholders.filter(x => x.id === id).map(x=>x.max);
	let placeholderId = "iw_placeholder"+id;
	var insertButton = <div className="iw_insert-btn" data-hp-event="open-component-browser">+</div>;
	var firstComponent = <div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row iw_prepend ui-droppable"><div className="iw_insert-line"></div></div>	
	var lastComponentLine = <div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row iw_append ui-droppable"><div className="iw_insert-line"></div></div>
	var middleComponentLine = <div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row ui-droppable"><div className="iw_insert-line"></div></div>
	
	let components = [];
	var index = 1;
	
	if(data.components){
		if(data.components.length < max && (mode === 'edit' || mode === 'template')){
			lastComponentLine = <div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row iw_append ui-droppable">{insertButton}<div className="iw_insert-line"></div></div>
			middleComponentLine =  <div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row ui-droppable">{insertButton}<div className="iw_insert-line"></div></div>
		}
		
		data.components.map(function(item) {
			if(index === 1 && (mode === 'edit' || mode === 'template')){
				components.push(firstComponent);
			}
			var contents = {details:{}};
			contents.details = item;
			
			let componentId = "iw_comp"+item.id;
			components.push(<TeamSiteComponents renderComponent={item.name} mode={mode} placeholders={placeholders} id={componentId} data={contents} placeholder="true" componentDetails = {componentDetails} componentsDefinition={componentsDefinition}/>);
			if(index < data.components.length && (mode === 'edit' || mode === 'template')){
				components.push(middleComponentLine);
			}
			index = index+1;
			return item;
		});
		if(index >= data.components.length && (mode === 'edit' || mode === 'template')){
				components.push(lastComponentLine);				
		}
		if( (mode === 'edit' || mode === 'template')){
			var componentsRemaining = max - data.components.length;
			placeholderTitle = componentsRemaining+" More Allowed ("+max+" Max)";
				return(
				<div className="iw_component-wrapper iw_placeholder-wrapper iw_status-active iw_status-active-head">
					<div className="iw_placeholder" id={placeholderId}>
						
						{components.map((row, i) =>
							row			
						)}   
						
					</div>
					<div className="iw-overlay iw-docked iw_status-multi" data-hp-view="statuslayer">
						<div className="iw_status-head iw_status-largelabel" >
							<div className="iw_status-multi-head iw_status_placeholder" title={placeholderTitle}>
								<span className="regular">{placeholderTitle}</span>
								<span className="compact">{componentsRemaining} More Allowed</span>
							</div>
						</div>
						<div className="iw_status-info">
							<div className="iw_status-multi-actions" >
								<button className="iw_status-multi-action-0" title="This is a placeholder that allows multiple components to be added (stacked) vertically during page editing" data-hp-event="execute-action">Add Component</button>
							</div>
						</div>
					</div>
				</div>
			);
		}else{
			return(
				<Fragment>
					{components.map((row, i) =>
					<Fragment key={i}>	{row}</Fragment>		
					)}   
				</Fragment>
			);
		}
	}else  if( (mode === 'template')){
		// eslint-disable-next-line
		let templatePropertiesMenu = <component-menu className="iw_component-menu" data-hp-view="componentmenu"><div className="left-group"><a className="iw_btn-name iw-drag-handle ui-draggable-handle" title="Move this component">Multi-Component Placeholder</a><div className="iw_component-menu-statuses"></div></div><div className="right-group"><a className="iw_btn-properties" title="Open this component's properties" data-hp-event="open-entity-props">&nbsp;</a><a className="iw_btn-delete" title="Delete this component" data-hp-event="delete-entity">&nbsp;</a></div></component-menu>
	
		return(
			<Fragment>
				<div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row iw_prepend ui-droppable"><div className="iw_insert-line"></div></div>
					<div className="iw_component-wrapper iw_placeholder-wrapper iw_empty ui-draggable">
						{templatePropertiesMenu}
						<div className="iw_placeholder" id={placeholderId}>
							<h2>MULTI-COMPONENT PLACEHOLDER</h2>
							<span>This is a placeholder that allows multiple components to be added (stacked) vertically during page editing.</span>						
						</div>					
					</div>  
				<div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row iw_append ui-droppable"><div className="iw_insert-btn" data-hp-event="open-component-browser">+</div><div className="iw_insert-line" ></div></div>
			</Fragment>
		);
	} else if (mode === 'edit'){
		placeholderTitle = max+" More Allowed ("+max+" Max)";
		return(
			<Fragment>
				<div className="iw_component-wrapper iw_placeholder-wrapper iw_empty iw_status-active iw_status-active-full">
					<div className="iw_placeholder" id={placeholderId}>
						<h2>MULTI-COMPONENT PLACEHOLDER</h2>
						<span>This is a placeholder that allows multiple components to be added (stacked) vertically during page editing.</span>
						<span className="iw_placeholder-instructions"><br/>  Toggle layers to add a component</span>
					</div>
					<div className="iw-overlay iw-docked iw_status-multi" data-hp-view="statuslayer">
						<div className="iw_status-head iw_status-largelabel" >
							<div className="iw_status-multi-head iw_status_placeholder" title={placeholderTitle}>
								<span className="regular">{placeholderTitle}</span>
								<span className="compact">{componentsRemaining} More Allowed</span>
							</div>
						</div>
						<div className="iw_status-info">
							<div className="iw_status-multi-actions" >
								<button className="iw_status-multi-action-0 placeholderImage" title="This is a placeholder that allows multiple components to be added (stacked) vertically during page editing" data-hp-event="execute-action">Add Component</button>
							</div>
						</div>
					</div>
				</div> 
			</Fragment>
		);
	
	
    }else{
		return(
			<Fragment>
				
			</Fragment>
		);
		
	}
  }
}

export default PlaceHolder;
