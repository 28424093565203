import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'normalize.css';
import 'typeface-nunito-sans';
import { withStyles } from '@material-ui/core/styles';
import MuiCssBaseline from '@material-ui/core/CssBaseline';

// CssBaseline adds body2 typography styles to the body element by default.
// Override that to use body1 instead.
const styles = theme => ({
  '@global': {
    body: {
      ...theme.typography.body1
    }
  }
});

class CssBaseline extends Component {
  static propTypes = {
    children: PropTypes.any
  };

  render() {
    return <MuiCssBaseline>{this.props.children}</MuiCssBaseline>;
  }
}

export default withStyles(styles)(CssBaseline);
