import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../Typography';
import Link from '../Link';

const styles = theme => ({
  root: {
    padding: '0 32px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  medium: {
    padding: '0 24px 18px'
  },
  small: {
    padding: '0 18px 12px'
  }
});

class Title extends Component {
  static displayName = 'Paper.Title';

  static propTypes = {
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
    children: PropTypes.any,
    count: PropTypes.number,
    translate: PropTypes.bool,
    paddingSize: PropTypes.oneOf(['sm', 'md', 'lg']),
    action: PropTypes.func,
    actionText: PropTypes.string
  };

  static defaultProps = {
    paddingSize: 'lg'
  };

  render() {
    const {
      children,
      classes,
      className,
      paddingSize,
      onClick,
      actionText,
      ...other
    } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          {
            [classes.medium]: paddingSize === 'md',
            [classes.small]: paddingSize === 'sm'
          },
          className
        )}
      >
        <Typography variant="h4" component="h2" {...other}>
          {children}
        </Typography>
        {actionText && (
          <Link onClick={onClick} variant="body2">
            {actionText}
          </Link>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Title);
