import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiIconButton from '@material-ui/core/IconButton';

class IconButton extends Component {
  static propTypes = {
    children: PropTypes.any,
    color: PropTypes.oneOf(['default', 'inherit', 'primary']),
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    edge: PropTypes.oneOf(['start', 'end', false]),
    size: PropTypes.oneOf(['small', 'medium']),
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
  };

  static defaultProps = {
    color: 'default',
    edge: false,
    size: 'medium',
  };

  render() {
    // Filter out unsupported material-ui props
    const {
      classes,
      disableRipple,
      // From ButtonBase component
      action,
      buttonRef,
      centerRipple,
      disableTouchRipple,
      focusRipple,
      focusVisibleClassName,
      onFocusVisible,
      TouchRippleProps,
      ...other
    } = this.props;

    return <MuiIconButton {...other} />;
  }
}

export default IconButton;
