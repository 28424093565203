import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1170,
      xl: 1920
    }
  },
  palette: {
    type: 'light',
    background: {
      paper: '#fff',
      default: '#f6f7f9'
    },
    primary: {
      main: '#0061af',
      dark: '#003764',
      text: '#0061af',
      contrastText: '#fff',
      light: 'rgba(225, 241, 252, 0.5)',
      contrastTextSecondary: 'rgba(255, 255, 255, 0.7)'
    },
    success: {
      main: '#5a963a',
      contrastText: '#fff',
      contrastTextSecondary: 'rgba(255, 255, 255, 0.7)'
    },
    error: {
      light: '#e5c1b3',
      main: '#d2451e',
      text: '#d2451e',
      contrastText: '#fff',
      contrastTextSecondary: 'rgba(255, 255, 255, 0.7)'
    },
    text: {
      primary: '#3F4145',
      secondary: '#737373',
      disabled: '#9d9d9e',
      hint: '#3F4145'
    },
    action: {
      // TODO: Not sure if selected and hover styles should be the same color. May need to revisit.
      selected: 'rgba(100, 176, 232, 0.1)',
      hover: 'rgba(100, 176, 232, 0.1)',
      text: '#003764',
      textSecondary: '#0061af',
      disabled: '#E3E3E3',
      disabledBackground: '#F1F1F1'
    },
    border: 'rgba(93, 112, 142, 0.3)',
    divider: 'rgba(0, 97, 175, 0.1)',
    // Used for bottom border to provide depth in conjunction with shadows,
    // like in Paper and default contained Button components
    dividerShadow: '#eff0f5',
    grey: {
      0: '#fff',
      50: '#fdfdfd',
      100: '#f6f7f9',
      200: '#eff2f5',
      300: '#e3e3e3',
      A100: '#f9f9f9'
    }
  },
  shadows: [
    'none',
    '0 2px 4px 1px rgba(88, 136, 176, 0.15)',
    '0 4px 8px 2px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    // All shadows past 3 are purposefully being overridden to this big shadow
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)',
    '0 8px 16px 5px rgba(88, 136, 176, 0.15)'
  ],
  zIndex: {
    app: 900,
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500
  },
  props: {
    MuiButtonBase: {
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  },
  overrides: {
    // Restore default focus styling, since many components rely on ButtonBase ripple for focus.
    MuiButtonBase: {
      root: {
        '&$focusVisible': {
          outline: '1px dotted #212121', // Edge, Firefox
          '&': {
            outline: '5px auto -webkit-focus-ring-color' // Safari, Chrome
          }
        }
      }
    }
  },
  shape: {
    borderRadius: 4
  },
  /* Styles applied to the root element if `variant="srOnly"`. Only accessible to screen readers. */
  srOnly: {
    position: 'absolute',
    height: 1,
    width: 1,
    overflow: 'hidden'
  },
  typography: {
    fontFamily:
      "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      color: '#3F4145',
      fontWeight: 600,
      fontSize: '2rem', // 32px
      lineHeight: 1.125,
      letterSpacing: 'normal'
    },
    h2: {
      color: '#3F4145',
      fontWeight: 400,
      fontSize: '1.875rem', // 30px
      lineHeight: 1,
      letterSpacing: 'normal'
    },
    h3: {
      color: '#3F4145',
      fontWeight: 300,
      fontSize: '1.375rem', // 22px
      lineHeight: 1.25,
      letterSpacing: 'normal'
    },
    h4: {
      color: '#3F4145',
      fontWeight: 600,
      fontSize: '1.125rem', // 18px
      lineHeight: 1.33
    },
    h5: {
      color: '#3F4145',
      fontWeight: 600,
      fontSize: '0.9375rem', // 15px
      lineHeight: 1.33
    },
    body1: {
      color: '#3F4145',
      fontWeight: 400,
      fontSize: '0.9375rem', // 15px
      lineHeight: 1.4,
      letterSpacing: 'normal'
    },
    body2: {
      color: '#3F4145',
      fontWeight: 400,
      fontSize: '0.8125rem', // 13px
      lineHeight: 1.4,
      letterSpacing: 'normal'
    },
    button: {
      color: '#3F4145',
      fontWeight: 600,
      fontSize: '0.9375rem', // 15px
      lineHeight: 1.33,
      letterSpacing: 'normal',
      textTransform: 'none'
    },
    caption: {
      color: '#737373',
      fontWeight: 600,
      fontSize: '0.75rem', // 12px
      lineHeight: 1.25,
      letterSpacing: 'normal'
    },
    overline: {
      color: '#737373',
      fontWeight: 600,
      fontSize: '0.5625rem', // 9px
      lineHeight: 1,
      letterSpacing: '0.5px',
      textTransform: 'uppercase'
    }
  }
});

class ThemeProvider extends Component {
  static propTypes = {
    children: PropTypes.any,
    theme: PropTypes.object
  };

  static defaultProps = {
    theme: defaultTheme
  };

  render() {
    const { children, theme } = this.props;

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }
}

export default ThemeProvider;
