import React, { Component } from 'react';
import PropTypes from 'prop-types';

const defaultContext = {
  auth: null,
  currentUser: null,
  selectedBroker: null,
  log: null,
  remote: null,
  setCurrentUser: () => {},
  setSelectedBroker: () => {},
  brokerDialogIsOpen: false,
  challengeQuestionsSent: false,
  openBrokerDialog: () => {},
  closeBrokerDialog: () => {},
  closeChallengeQuestionsDialog: () => {},
};

const Context = React.createContext(defaultContext);

class Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultContext,
      setCurrentUser: this.setCurrentUser,
      setSelectedBroker: this.setSelectedBroker,
      openBrokerDialog: this.openBrokerDialog,
      closeBrokerDialog: this.closeBrokerDialog,
      closeChallengeQuestionsDialog: this.closeChallengeQuestionsDialog,
      ...props,
    };
  }

  setCurrentUser = (currentUser) => {
    window.currentUser = () => currentUser;
    if (window.initiateHightlightIO) {
      window.initiateHightlightIO(currentUser);
    }
    if (window.initiateBrokerChat) {
      window.initiateBrokerChat(currentUser);
    }
    this.setState({
      currentUser,
      selectedBroker: currentUser.isBroker ? currentUser : null,
    });
  };

  setSelectedBroker = (selectedBroker) => {
    this.setState({ selectedBroker });
  };

  openBrokerDialog = () => {
    this.setState({ brokerDialogIsOpen: true });
  };

  closeBrokerDialog = () => {
    this.setState({ brokerDialogIsOpen: false });
  };

  closeChallengeQuestionsDialog = () => {
    this.setState({ challengeQuestionsSent: true });
  };

  render() {
    const { children } = this.props;
    const value = { ...this.state };
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }
}

Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
Provider.defaultProps = { children: null };

export default Context;
export { Provider }; 
