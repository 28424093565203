import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    padding: '0 32px 32px'
  },
  medium: {
    padding: '0 24px 24px'
  },
  small: {
    padding: '0 18px 18px'
  }
});

class Content extends Component {
  static displayName = 'Paper.Content';

  static propTypes = {
    children: PropTypes.any,
    paddingSize: PropTypes.oneOf(['sm', 'md', 'lg'])
  };

  static defaultProps = {
    paddingSize: 'lg'
  };

  render() {
    const { children, classes, className, paddingSize, ...other } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          {
            [classes.medium]: paddingSize === 'md',
            [classes.small]: paddingSize === 'sm'
          },
          className
        )}
        {...other}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Content);
