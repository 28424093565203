import React, {Fragment} from 'react';
import { PREVIEW_MODE } from '../../utils/teamSiteConstants';

import RenderCmsComponent from './renderComponents';

/**
This is the main class to render the components on the page using the json response. 
It reads the layout and add the layout in the bootstrap format based on how TeamSite Estudio provides the grid structure
*/
export default class CmsPage extends React.Component { 

  render() {
    const { mode ,model, componentsDefinition }  = this.props;
	
    if (!model || !model.page) {
      return null;
    }
	
	let addComponentPointForEmptyContainer = <component-point data-hp-view="componentpoint" className="iw_insert-point iw_insert-section iw_insert-row iw_full-width ui-droppable"><div className="iw_insert-btn" data-hp-event="open-component-browser">+</div><div className="iw_insert-line"></div><div className="iw_container"><div className="iw_insert-point iw_insert-row iw_insert-section iw_container-width ui-droppable"><div className="iw_insert-btn inner" data-hp-event="open-component-browser">+</div><div className="iw_insert-line"></div><div className="iw_instructions">Add a component that occupies the template's width</div></div></div><div className="iw_instructions">Add a component that occupies the entire width</div></component-point>
	
	let addComponentPoint = <div data-hp-view="componentpoint" className="iw_insert-point iw_insert-section iw_insert-row iw_full-width ui-droppable"><div className="iw_insert-btn" data-hp-event="open-component-browser">+</div><div className="iw_insert-line"></div><div className="iw_container"><div className="iw_insert-point iw_insert-row iw_insert-section iw_container-width ui-droppable"><div className="iw_insert-btn inner" data-hp-event="open-component-browser">+</div><div className="iw_insert-line"></div></div></div></div>
	
	// This code is to identify the out of date temlates and components so we get the option to merge or overwrite the latest template or component
	// Out of Date template is only checked on pages in edit mode
	var templateOutOfDate = "";
	var componentDetails ="";
	if(mode === 'edit'){
		var jsonObject = model.templateDetail;
		if(jsonObject.outOfDate === true){
			// eslint-disable-next-line
			templateOutOfDate = <div className="iw-overlay iw_status-tempoutofdate" data-hp-view="statuslayer"><div className="iw_status-head iw_status-largelabel"><div className="iw_status-tempoutofdate-head  iw_outofstatus"  title="New Version Available"><span className="regular">New Version Available</span><span className="compact">Update Available</span><a className="iw_status-tempoutofdate-expand" data-hp-event="expand-status"></a><a className="iw_status-tempoutofdate-close" data-hp-event="dismiss-status"></a></div></div><div className="iw_status-info"><div className="iw_status-tempoutofdate-actions iw_templateOutOfDate_actions_style" ><button className="iw_status-tempoutofdate-action-0" title="Merge Template"  data-hp-event="execute-action">Merge</button><button className="iw_status-tempoutofdate-action-1" title="Overwrite Template"  data-hp-event="execute-action">Overwrite</button></div></div></div>
		}		
	}
	//Out of date components are checked in page edit and template mode
	if(mode === 'edit' || mode === 'template'){
		componentDetails = model.componentDetails;
	}
		
	var containers =[];
	var i = 0 ;
	//Render all containers. All containers are surrounded by components points to be able to add components and drag and drop in Estudio edit mode. Since it is only allowed in template mode we are checking for that mode. and adding the reuired tags
	if(model.page.containers.length === 0){
		if( ( mode === 'template')){
				containers.push(addComponentPointForEmptyContainer);
			}
	}else{
		for (i in model.page.containers) {
			//when its first container we add a different component point
			if( i <= 0 && ( mode === 'template')){
				containers.push(<div data-hp-view="componentpoint" className="iw_insert-point iw_insert-section iw_insert-row iw_full-width ui-droppable"><div className="iw_insert-line"></div><div className="iw_container"><div className="iw_insert-point iw_insert-row iw_insert-section iw_container-width ui-droppable"><div className="iw_insert-line"></div></div></div></div>);
			}
			containers.push(<RenderCmsComponent renderComponent={model.page.containers[i].type} mode={mode} placeholders={model.page.placeholders} id={model.page.containers[i].id} data={model.page.containers[i]} componentDetails={componentDetails} componentsDefinition={componentsDefinition}/>);		
			if( ( mode === 'template')){
				containers.push(addComponentPoint);
			}
		}
	}	
	
	let styleClassMain = (mode === PREVIEW_MODE.TEMPLATE || mode === PREVIEW_MODE.EDIT) ? "iw_viewport-wrapper":"iw_viewport_runtime-wrapper";
	 return (
       <div className={styleClassMain}>{containers.map((row, i) =><Fragment key={i}>{row}</Fragment>)}{templateOutOfDate}</div>
    );	
  }
}
