import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import Typography from '../Typography';

const StyledButton = withStyles(theme => ({
  root: {
    transition: 'none'
  },
  contained: {
    borderBottom: `2px solid ${theme.palette.dividerShadow}`,
    padding: '5px 16px',
    color: theme.palette.action.text,
    backgroundColor: theme.palette.grey[0],
    '&:hover, &:focus': {
      color: theme.palette.action.textSecondary,
      backgroundColor: theme.palette.grey.A100,
      // Reset hover styling on touch devices
      '@media (hover: none)': {
        color: theme.palette.action.text,
        backgroundColor: theme.palette.grey[0]
      }
    },
    '&$disabled': {
      boxShadow: theme.shadows[1],
      color: theme.palette.text.disabled
    }
  },
  containedPrimary: {
    borderBottom: 'none',
    color: theme.palette.primary.contrastText,
    background: `linear-gradient(316.31deg, ${theme.palette.primary.main} 0%, ${
      theme.palette.primary.dark
    } 100%)`,
    '&:hover, &:focus ': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.dark,
      // Reset hover styling on touch devices
      '@media (hover: none)': {
        color: theme.palette.primary.contrastText,
        background: `linear-gradient(316.31deg, ${
          theme.palette.primary.main
        } 0%, ${theme.palette.primary.dark} 100%)`
      }
    },
    '&$disabled': {
      boxShadow: theme.shadows[1],
      color: theme.palette.text.disabled
    }
  },
  disabled: {
    backgroundImage: 'none'
  },
  sizeSmall: {
    padding: '2px 12px',
    fontSize: theme.typography.pxToRem(12)
  },
  sizeLarge: {
    padding: '15px 24px'
  }
}))(MuiButton);

class Button extends Component {
  static propTypes = {
    children: PropTypes.any,
    color: PropTypes.oneOf(['default', 'primary']),
    disabled: PropTypes.bool,
    href: PropTypes.string,
    minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.func
    ]),
    translate: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset'])
  };

  static defaultProps = {
    color: 'default',
    minWidth: 136,
    size: 'medium',
    translate: true,
    type: 'button'
  };

  render() {
    const {
      buttonRef,
      children,
      href,
      minWidth,
      to,
      translate,
      // Filter out unsupported material-ui props
      classes,
      component,
      disableFocusRipple,
      disableRipple,
      fullWidth,
      mini,
      variant,
      // From ButtonBase component
      action,
      centerRipple,
      disableTouchRipple,
      focusRipple,
      focusVisibleClassName,
      onFocusVisible,
      TouchRippleProps,
      ...other
    } = this.props;

    // Render Button as react-router Link, <a> tag, or <button> based on props
    const ButtonComponent = to ? Link : href ? 'a' : 'button';

    return (
      <StyledButton
        component={ButtonComponent}
        variant="contained"
        style={{ minWidth }}
        to={to}
        href={href}
        buttonRef={buttonRef}
        {...other}
      >
        <Typography variant="inherit" color="inherit" translate={translate}>
          {children}
        </Typography>
      </StyledButton>
    );
  }
}

// Forward ref, so you can use regular `ref` instead of Material UI's custom `buttonRef` prop
export default React.forwardRef((props, ref) => (
  <Button {...props} buttonRef={ref} />
));
