import auth0 from 'auth0-js';
import { nanoid } from 'nanoid';
import jwtDecode from 'jwt-decode';

class Auth {
  accessToken = sessionStorage.getItem('accessToken');
  idToken = sessionStorage.getItem('idToken');
  expiresAt = sessionStorage.getItem('expiresAt');
  roleStorage = sessionStorage.getItem('roles');
  roles = this.roleStorage !== undefined ? JSON.parse(this.roleStorage) : [];

  constructor({
    domain,
    clientID,
    redirectUri,
    responseType,
    scope,
    audience,
    realm,
  }) {
    this.auth = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri,
      responseType,
      scope,
      audience,
      realm,
    });
  }

  // Authorize with Auth0. Generates a random nonce and saves "state" param in
  // sessionStorage with that nonce as the key, so it can be restored after auth.
  // Reference: https://auth0.com/docs/protocols/oauth2/redirect-users
  login = (state) => {
    const nonce = nanoid();
    sessionStorage.setItem('nonce', nonce);
    sessionStorage.setItem(nonce, JSON.stringify(state));
    this.auth.authorize({ state: nonce });
  };

  logoutUrl = () => (
    `https://${
      window._env_.AUTH0_SWITCH === 'false'
        ? window._env_.REACT_APP_AUTH_DOMAIN
        : window._env_.REACT_APP_AUTH_DOMAIN_BUSINESS
    }/v2/logout?client_id=${
      window._env_.AUTH0_SWITCH === 'false'
        ? window._env_.REACT_APP_AUTH_CLIENT_ID
        : window._env_.REACT_APP_AUTH_CLIENT_ID_BUSINESS
    }&returnTo=${window._env_.REACT_APP_AUTH_LOGIN_URL}`
  );

  logout = () => {
    const nonce = sessionStorage.getItem('nonce');
    sessionStorage.removeItem(nonce);
    sessionStorage.removeItem('nonce');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('expiresAt');
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;
    window.location.replace(this.logoutUrl());
  };

  // Check whether the current time is past the access token's expiry time
  isAuthenticated = () => {
    return new Date().getTime() < this.expiresAt;
  };

  // Handle authentication after redirect from Auth0 login. Parses tokens from
  // URL, and restores state saved in sessionStorage, if any.
  handleAuthentication = () =>
    new Promise((resolve, reject) => {
      const nonce = sessionStorage.getItem('nonce');
      this.auth.parseHash({ state: nonce }, (err, authResult) => {
        if (err) {
          return reject(`Authorization error: ${err.error}`);
        }
        let state;
        try {
          state = JSON.parse(sessionStorage.getItem(nonce));
        } catch (error) {
          // Don't do anything with error. Just leave state undefined.
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          return resolve({ ...authResult, state });
        } else {
          return reject('Unknown error during login');
        }
      });
    });

  setSession = ({ accessToken, idToken, expiresIn }) => {
    const expiresAt = expiresIn * 1000 + new Date().getTime();
    const decodedToken = jwtDecode(accessToken);
    const roles = decodedToken[window._env_.REACT_APP_AUTH_ROLE_CLAIM] || [];

    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('idToken', idToken);
    sessionStorage.setItem('expiresAt', expiresAt);
    sessionStorage.setItem('roles', JSON.stringify(roles));
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.expiresAt = expiresAt;
    this.roles = roles;
  };
}

export default Auth;
