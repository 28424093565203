import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';

import  Columns  from './Columns';

/**
This components adds the rows div around the components wrapped in columns
*/
class Rows extends Component {
 
  render() {
    const { data,  id, mode, placeholders,  componentDetails, componentsDefinition} = this.props;
	var styles = "";
	
	if(mode === 'edit' || mode === 'template'){
		styles = "iw_row ";
	}
	data.styles.map((item)=> styles+=item+" ");
	//In template mode we have to add component points surrounding the rows to enable moving of components in template edit mode
	if( (mode === 'template')){
		 return (							
				<Grid container direction="row" className={styles} id={id}>
					<div data-hp-view="componentpoint" className="iw_insert-point iw_insert-column iw_prepend ui-droppable"><div className="iw_insert-line"></div></div>
						 {data.columns.map((col,k) =>
							<Fragment key={k}><Columns renderComponent={col.type} mode={mode} placeholders={placeholders} id={col.id} data={col} index={k} componentDetails = {componentDetails} componentsDefinition={componentsDefinition}/></Fragment>
						)}    
					 <div data-hp-view="componentpoint" className="iw_insert-point iw_insert-column iw_append ui-droppable"><div className="iw_insert-line"></div></div>
				</Grid>			
			);
	
	}
	return (							
				<Grid container direction="row" className={styles} id={id}>
					 {data.columns.map((col,i) =>
						<Fragment key={i}><Columns renderComponent={col.type} mode={mode} placeholders={placeholders} id={col.id} data={col} componentDetails = {componentDetails} componentsDefinition={componentsDefinition}/></Fragment>
					)}    
				</Grid>			
	);
  }
}

export default Rows;
