/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
import React from 'react';

// Mapping for TeamSite Components
export const COMPONENTS_DEFINITION = {
  'React/POC/Alert.component': React.lazy(() =>
    import('Shared/components/POC/Alert')
  ),
  'React/Broker/Profile.component': React.lazy(() =>
    import('Broker/components/Profile')
  ),
  'React/Common/Footer.component': React.lazy(() =>
    import('Shared/components/Footer')
  ),
  'React/Common/WYSIWYG.component': React.lazy(() =>
    import('Shared/components/WYSIWYG')
  ),
  'React/Broker/Header.component': React.lazy(() =>
    import('Broker/components/Header')
  ),
  'React/Common/Search Results.component': React.lazy(() =>
    import('Shared/components/Search')
  ),
  'React/Common/Card.component': React.lazy(() =>
    import('Shared/components/ContentCard')
  ),
  'React/Common/Hero.component': React.lazy(() =>
    import('Shared/components/Hero')
  ),
  'React/Broker/Medicare.component': React.lazy(() =>
    import('Broker/components/Medicare')
  ),
  'React/Broker/MyTeam.component': React.lazy(() =>
    import('Broker/components/MyTeamPage')
  ),
  'React/Broker/CreateAgencyAdmin.component': React.lazy(() =>
    import('Broker/components/CreateSupportUser')
  ),
  'React/Broker/Individual.component': React.lazy(() =>
    import('Broker/components/Individual')
  ),
  'React/Broker/Commissions.component': React.lazy(() =>
    import('Broker/components/Commissions')
  ),
  SsoLink: React.lazy(() => import('Broker/components/SSO')),
  'React/Broker/Group.component': React.lazy(() =>
    import('Broker/components/Group')
  ),
  'React/Common/Tabbed Content.component': React.lazy(() =>
    import('Shared/components/TabbedContent')
  ),
	"React/Broker/ShopAndEnrollURL.component": React.lazy(() =>
    import('Broker/components/EnrollUrl')
  ),
};
