import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Context from '../../../Context';
import CircularProgress from '../../../Shared/components/CircularProgress';

class Callback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      authState: null,
    };
  }

  async componentDidMount() {
    const { auth } = this.context;
    try {
      const { state } = await auth.handleAuthentication();
      this.setState({ authenticated: true, authState: state });
    } catch (error) {
      // Failed to finish authentication. Try again.
      this.setState({ authenticated: false, authState: null }, () => {
        auth.login();
      });
    }
  }

  render() {
    const { authenticated, authState } = this.state;

    return authenticated ? (
      <Redirect to={authState || '/'} />
    ) : (
      <CircularProgress data-test-id="route-loading" block />
    );
  }
}

Callback.contextType = Context;

// Supports same props as react-router-dom Route component,
const CallbackRoute = ({ component, ...other }) => (
  <Route {...other} component={Callback} />
);

CallbackRoute.propTypes = {
  children: PropTypes.any,
  component: PropTypes.node,
};
CallbackRoute.defualtProps = {
  children: null,
  component: null,
};

export default CallbackRoute;
