import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiPaper from '@material-ui/core/Paper';
import CircularProgress from '../CircularProgress';
import Content from './Content';
import Title from './Title';
import classNames from 'classnames';

const StyledPaper = withStyles(theme => ({
  root: {
    padding: '32px 0 0',
    borderBottom: `2px solid ${theme.palette.dividerShadow}`,
    position: 'relative'
  }
}))(MuiPaper);

const styles = theme => ({
  loading: {
    background: 'rgba(255, 255, 255, 0.5)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  medium: {
    padding: '24px 0 0'
  },
  small: {
    padding: '18px 0 0'
  }
});

class Paper extends Component {
  static Content = Content;
  static Title = Title;

  static propTypes = {
    children: PropTypes.any,
    component: PropTypes.elementType,
    loading: PropTypes.bool,
    paddingSize: PropTypes.oneOf(['sm', 'md', 'lg']),
    elevation: PropTypes.number
  };

  static defaultProps = {
    elevation: 4,
    paddingSize: 'lg'
  };

  render() {
    const {
      children,
      classes,
      loading,
      elevation,
      paddingSize,
      ...other
    } = this.props;

    const childrenMap = React.Children.map(children, child => {
      if (!child) return null;
      if (child.type !== Content && child.type !== Title) {
        return child;
      }
      return React.cloneElement(
        child,
        { paddingSize },
        child.props.children || null
      );
    });

    return (
      <StyledPaper
        elevation={elevation}
        className={classNames(classes.root, {
          [classes.small]: paddingSize === 'sm',
          [classes.medium]: paddingSize === 'md'
        })}
        {...other}
      >
        {childrenMap}
        {loading && (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        )}
      </StyledPaper>
    );
  }
}

export default withStyles(styles)(Paper);
