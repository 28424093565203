import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import { SecureRoute, CallbackRoute } from 'Broker/components/Auth';
import InactivityTimer from 'components/InactivityTimer';
import Error from 'pages/Error';
import Health from 'pages/Health';
import NotFound from 'pages/NotFound';
import LSDSPage from 'pages/LSDS';

const App = () => {
  const [activityTimeoutSeconds] = useState(60 * 14);
  const [logoutTimeoutSeconds] = useState(60);

  return (
    <Switch>
      <Route path="/health" component={Health} />
      <CallbackRoute path="/login" />

      <SecureRoute
        // eslint-disable-next-line no-unused-vars
        render={(props) => (
          <>
            <InactivityTimer
              activityTimeoutSeconds={activityTimeoutSeconds}
              logoutTimeoutSeconds={logoutTimeoutSeconds}
            />
            <Switch>
              <SecureRoute
                path="/profile/:id"
                render={({ match }) => <LSDSPage match={match} />}
              />
              <SecureRoute path="/*" exact component={LSDSPage} />
            </Switch>
          </>
        )}
      />
      <Route path="/error" component={Error} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withApollo(App);
