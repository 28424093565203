import React, { Component, Fragment } from 'react';

import RenderCmsComponent from './renderComponents';

/**
This class renders the react components on the page sorrounded by the div tags of TeamSite if the application is loaded in edit or template mode
*/
class TeamSiteComponents extends Component {
 
  render() {
    const { data,  id, mode, placeholders,  placeholder, componentDetails, componentsDefinition} = this.props;
	
	let componentName = data.details.name;
	
	if(componentName === 'UnsupportedComponent'){
		componentName = data.details.path;
	}
	
	// eslint-disable-next-line
	let componentMenu = <component-menu className="iw_component-menu" data-hp-view="componentmenu"><div className="left-group"><a className="iw_btn-name">{componentName}</a><div className="iw_component-menu-statuses"></div></div><div className="right-group"><a className="iw_btn-properties" title="Open this component's properties" data-hp-event="open-entity-props">&nbsp;</a></div></component-menu>;	
	// eslint-disable-next-line
	let componentMenuForTemplate = <component-menu className="iw_component-menu" data-hp-view="componentmenu"><div className="left-group"><a className="iw_btn-name">{componentName}</a><div className="iw_component-menu-statuses"></div></div><div className="right-group"><a className="iw_btn-properties" title="Open this component's properties" data-hp-event="open-entity-props">&nbsp;</a><a className="iw_btn-clone" title="Clone this component" data-hp-event="clone-component">&nbsp;</a><a className="iw_btn-delete" title="Delete this component" data-hp-event="delete-entity">&nbsp;</a></div></component-menu>;	
	// eslint-disable-next-line
	let componentMenuForPlaceholder = <component-menu className="iw_component-menu" data-hp-view="componentmenu"><div className="left-group"><a className="iw_btn-name">{componentName}</a><div className="iw_component-menu-statuses"></div></div><div className="right-group"><a className="iw_btn-properties" title="Open this component's properties" data-hp-event="open-entity-props">&nbsp;</a><a className="iw_btn-delete" title="Delete this component" data-hp-event="delete-entity">&nbsp;</a></div></component-menu>;
	
	let componentStatusLayer = "";
	let errorStatusLayer =  "";
	var i = 0 ;
	

	// First check if edit or template mode, then see if the component is out of date. We need to provide the option to merge or overwrite component if its out of date
	if(mode === 'edit' || mode === 'template'){
		for (i in componentDetails){
			if(id.includes(componentDetails[i].id)){
				if(componentDetails[i].outOfDate === true){
					// eslint-disable-next-line
					componentStatusLayer = <div className="iw-overlay iw_status-compoutofdate" data-hp-view="statuslayer"><div className="iw_status-head iw_status-largelabel"><div className="iw_status-compoutofdate-head iw_outofstatus" title="New Version Available"><span className="regular">New Version Available</span><span className="compact">Update Available</span><a className="iw_status-compoutofdate-close" data-hp-event="dismiss-status"></a></div></div><div className="iw_status-info"><div className="iw_status-compoutofdate-actions" ><button className="iw_status-compoutofdate-action-0 iw_compOutOfDateMergeImage" title="Merge Component"  data-hp-event="execute-action">Merge</button><button className="iw_status-compoutofdate-action-1 iw_compOutOfDateOverwriteImage" title="Overwrite Component" data-hp-event="execute-action">Overwrite</button></div></div></div>
					break;
				}
			}
		};	
		// If there is error in the component it should show the exception. Or if the component is not supported
		if(!(data.details.path in componentsDefinition) || data.details.error && data.details.error === true){
			// eslint-disable-next-line
			 errorStatusLayer = <div className="iw-overlay iw_status-compexception" data-hp-view="statuslayer"><div className="iw_status-head iw_status-largelabel" ><div className="iw_status-compexception-head iw_component_exception_head"  title="Component Exception"><span className="regular">Component Exception</span><span className="compact">Exception</span><a className="iw_status-compexception-close" data-hp-event="dismiss-status"></a></div></div><div className="iw_status-info"><div className="iw_status-compexception-actions" ><button className="iw_status-compexception-action-0 iw_component_exception_statusImage" title="View Exception"  data-hp-event="execute-action">View Exception</button></div></div></div>
		}
	}
	
	let componentRenderDiv = <div className="iw_component" id={id}><RenderCmsComponent renderComponent={data.details.path} mode={mode} placeholders={placeholders} id={id} data={data.details} componentsDefinition={componentsDefinition}/></div>;
		
    // If the component goes in placeholder then we need to add the placeholder tags surrounding the components
	if (placeholder && mode === 'edit') {
		return (
					<div className="iw_component-wrapper">
						{componentMenuForPlaceholder}
						{componentRenderDiv}
						{componentStatusLayer}	
						{errorStatusLayer}						
					</div>
		);		
	}else if(mode === 'edit'){
		return (
					<div className="iw_component-wrapper">
						{componentMenu}
						{componentRenderDiv}
						{componentStatusLayer}
						{errorStatusLayer}
					</div>
		);		
	}else if(mode === 'template'){
		return (
					<Fragment>
						<div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row iw_prepend ui-droppable">
							<div className="iw_insert-line"></div></div><div className="iw_component-wrapper ui-draggable">
								{componentMenuForTemplate}
								{componentRenderDiv}
								{componentStatusLayer}
								{errorStatusLayer}
							</div>
							<div data-hp-view="componentpoint" className="iw_insert-point iw_insert-row iw_append ui-droppable">
								<div className="iw_insert-btn" data-hp-event="open-component-browser">+</div>
								<div className="iw_insert-line" >
							</div>
						</div>
					</Fragment>
		);
	}
			
	
	return <RenderCmsComponent renderComponent={data.details.path} mode={mode} placeholders={placeholders} id={data.details.id} data={data.details} componentsDefinition={componentsDefinition}/>;
  }
}

export default TeamSiteComponents;
