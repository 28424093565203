import { Component } from 'react';
import PropTypes from 'prop-types';
import Context from '../../../Context';

class Authorized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
    };
  }

  componentDidMount() {
    const { auth } = this.context;
    const { roles } = this.props;

    const authorized =
      typeof roles === 'string'
        ? auth.roles.includes(roles)
        : roles.some((role) => auth.roles.includes(role));

    this.setState({
      authorized,
    });
  }

  render() {
    const { children } = this.props;
    const { authorized } = this.state;
    if (authorized) return children;
    return null;
  }
}

Authorized.contextType = Context;

Authorized.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Authorized.defaultProps = {
  roles: [],
  children: null,
};

export default Authorized;
